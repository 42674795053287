import React, { useState } from "react";
import {
  COMPANY_NAME,
  PRODUCT_NAME_GEO,
  PRODUCT_NAME_TRANSMIT,
} from "../../constant";

const solutionMenu = [
  {
    href: "/solutions/secure-data-feed",
    title: PRODUCT_NAME_TRANSMIT,
  },
  {
    href: "/solutions/location-and-mapping",
    title: PRODUCT_NAME_GEO,
  },
];

const useCaseMenu = [
  {
    href: "/use-case/real-estate",
    title: "Real Estate",
  },
  {
    href: "/use-case/food-beverage",
    title: "Food & Beverage",
  },
  {
    href: "/use-case/tourism",
    title: "Tourism",
  },
  {
    href: "/use-case/healthcare",
    title: "Healthcare",
  },
  {
    href: "/use-case/banking",
    title: "Banking",
  },
  {
    href: "/use-case/retail",
    title: "Retail",
  },
];

const companyMenu = [
  {
    href: "/about",
    title: "About Us",
  },
  {
    href: "/contact",
    title: "Contact Us",
  },
];

const SUBMENU_KEYS = {
  SOLUTTIONS: "SOLUTTIONS",
  USECASES: "USECASES",
  COMPANY: "COMPANY",
};

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isUseCasesOpen, setIsUseCasesOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const openSubMenu = (key) => {
    if (key === SUBMENU_KEYS.SOLUTTIONS) {
      setIsSolutionsOpen(true);
      setIsUseCasesOpen(false);
      setIsCompanyOpen(false);
    } else if (key === SUBMENU_KEYS.USECASES) {
      setIsSolutionsOpen(false);
      setIsUseCasesOpen(true);
      setIsCompanyOpen(false);
    } else if (key === SUBMENU_KEYS.COMPANY) {
      setIsSolutionsOpen(false);
      setIsUseCasesOpen(false);
      setIsCompanyOpen(true);
    }
  };

  return (
    <>
      <div
        id="nav"
        className="hidden lg:grid grid-cols-12 gap-5 my-10 items-center"
      >
        <a href="/" className="col-start-2 cursor-pointer text-xl">
          <img src="/home/ACIAS_trans_bg.png"/>
        </a>
        <nav className="text-md flex gap-5 col-start-3 col-end-11 justify-center">
        <div className="font-firacode group relative dropdown hover:underline underline-offset-4 cursor-pointer">
            <a className="p-3" href="/">Home</a>
          </div>
          <div className="font-firacode group relative dropdown hover:underline underline-offset-4 cursor-pointer">
            <a className="p-3">Solutions</a>
            <div className="group-hover:block dropdown-menu absolute hidden h-auto mt-2 z-50">
              <ul className="w-80 bg-white shadow p-5 flex flex-col gap-5">
                {solutionMenu.map((item) => (
                  <li key={item.title}>
                    <a
                      href={item.href}
                      className="cursor-pointer flex justify-between items-center hover:text-slate-500"
                    >
                      {item.title}
                      <svg
                        width="21"
                        height="8"
                        viewBox="0 0 21 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="font-firacode group relative dropdown hover:underline underline-offset-4 cursor-pointer">
            <a className="p-3">Company</a>
            <div className="group-hover:block dropdown-menu absolute hidden h-auto mt-2 z-50">
              <ul className="w-56 bg-white shadow p-5 flex flex-col gap-5">
                {companyMenu.map((item) => (
                  <li key={item.title}>
                    <a
                      href={item.href}
                      className="cursor-pointer flex justify-between items-center hover:text-slate-500"
                    >
                      {item.title}
                      <svg
                        width="21"
                        height="8"
                        viewBox="0 0 21 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
                          fill="black"
                        />
                      </svg>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
        <a
          href="/contact"
          className="col-start-11 uppercase font-firacode bg-primary py-3 px-6 text-white w-max tracking-widest cursor-pointer hover:bg-slate-900"
        >
          Let's Talk
        </a>
      </div>
      <div className="lg:hidden grid grid-cols-12 my-6">
        <a href="/" className="col-start-2 col-span-3 cursor-pointer text-md flex items-center">
          <img className="object-cover" src="/home/ACIAS_trans_bg.png"/>
        </a>
        <div className="col-start-11">
          <svg
            onClick={() => setIsOpen(true)}
            fill="#000000"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30px"
            height="30px"
          >
            <path d="M 3 7 A 1.0001 1.0001 0 1 0 3 9 L 27 9 A 1.0001 1.0001 0 1 0 27 7 L 3 7 z M 3 14 A 1.0001 1.0001 0 1 0 3 16 L 27 16 A 1.0001 1.0001 0 1 0 27 14 L 3 14 z M 3 21 A 1.0001 1.0001 0 1 0 3 23 L 27 23 A 1.0001 1.0001 0 1 0 27 21 L 3 21 z" />
          </svg>
          {isOpen && (
            <nav
              className={`${
                isOpen ? "fixed" : "hidden"
              } top-0 left-0 z-10 w-full h-full bg-white`}
            >
              <div className="w-full flex justify-end pt-10 pr-10">
                <svg
                  className="mr-0"
                  onClick={() => setIsOpen(false)}
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.68318 1.72364C0.757273 2.67182 0 3.74227 0 4.10318C0 4.46364 2.29773 7.06273 5.10636 9.87909L10.2123 15L5.10636 20.1209C2.29773 22.9373 0 25.5545 0 25.9377C0 26.8114 3.22045 30 4.10318 30C4.46364 30 7.06273 27.7023 9.87909 24.8936L15 19.7877L20.1209 24.8936C22.9373 27.7023 25.5545 30 25.9377 30C26.8114 30 30 26.7795 30 25.8968C30 25.5364 27.7023 22.9373 24.8936 20.1209L19.7877 15L24.8936 9.87909C27.7023 7.06273 30 4.44545 30 4.06227C30 3.18864 26.7795 0 25.8968 0C25.5364 0 22.9373 2.29773 20.1209 5.10636L15 10.2123L9.87909 5.10636C7.06273 2.29773 4.44545 0 4.06227 0C3.67955 0 2.60909 0.775455 1.68318 1.72364Z"
                    fill="black"
                  />
                </svg>
              </div>
              <div className="flex flex-col items-center justify-center h-full">
                <a href="/" className="block w-full text-center text-3xl py-5">Home</a>
                <a
                  onClick={() => openSubMenu(SUBMENU_KEYS.SOLUTTIONS)}
                  className="block w-full text-center text-3xl py-5"
                >
                  Solutions
                </a>
                <div className={`${isSolutionsOpen ? "block" : "hidden"}`}>
                  {solutionMenu.map((menu) => (
                    <a
                      href={menu.href}
                      className="block w-full text-center text-md py-2"
                    >
                      {menu.title}
                    </a>
                  ))}
                </div>
                {/* <a
                  onClick={() => openSubMenu(SUBMENU_KEYS.USECASES)}
                  className="block w-full text-center text-4xl py-5"
                >
                  Use Cases
                </a>
                <div className={`${isUseCasesOpen ? "block" : "hidden"}`}>
                  {useCaseMenu.map((menu) => (
                    <a
                      href={menu.href}
                      className="block w-full text-center text-2xl py-2"
                    >
                      {menu.title}
                    </a>
                  ))}
                </div> */}
                <a onClick={() => openSubMenu(SUBMENU_KEYS.COMPANY)} className="block w-full text-center text-3xl py-5">
                  Company
                </a>
                <div className={`${isCompanyOpen ? "block" : "hidden"}`}>
                  {companyMenu.map((menu) => (
                    <a
                      href={menu.href}
                      className="block w-full text-center text-md py-2"
                    >
                      {menu.title}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};

export default Navigation;
