import * as React from "react";
import { PRODUCT_NAME_GEO } from "../../../constant";
import ContactUs from "../../components/ContactUs";
import "../../styles/main.css";
import Layout from "../../components/Layout";

const keyFeature = [
  [
    {
      title:"Navigation with Street View Image",
      content:"Enhances navigation by providing visual context for better decision-making and a more immersive experience.",
      img:"/geo/geo_street.png"
    },
    {
      title:"On-premises platform",
      content:"Sensitive Geospatial data are keep within organization’s infrastructure. Business have greater control over customization and integration with other existing systems. ",
      img:"/geo/geo_platform.png"
    },
  ],
  [
    {
      title:"Search and Filtering",
      content:"To locate specific places, points of interest, or geographic coordinates within a map.",
      img:"/geo/geo_search.png"
    },
    {
      title:"Actionable insights with AI",
      content:"Provides additional details that help viewers understand the image better, such as labels or annotations explaining specific features.",
      img:"/geo/geo_ai.png"
    },
  ],
  [
    {
      title:"High Performance Map",
      content:"Offer high precision with detailed and accurate geographic information. Regularly updated data to ensure relevance.",
      img:"/geo/geo_map.png"
    },
    {
      title:"Point of Interest Layer Management",
      content:"Ensures that users view only the layers pertinent to their roles or needs, enhancing focus and efficiency.",
      img:"/geo/geo_layer.png"
    },
  ]
]

const dataset = [
  {
    title:"Human Profile",
    content:"Encompasses detailed demographics information for targeted decision-making",
    img:"/geo/profile.svg"
  },
  {
    title:"Human Mobility",
    content:"Provides information on how people travel through different areas",
    img:"/geo/mobility.svg"
  },
  {
    title:"Web Usage",
    content:"Offer user interactions with websites providing insights to user behaviour and website performance ",
    img:"/geo/internet.svg"
  },
  {
    title:"Cruise Port Schedule",
    content:"Offers information on arrival and departure times of cruise ships, port availability, and passenger traffic",
    img:"/geo/cruise.svg"
  },
  {
    title:"Flight Schedule",
    content:"Offers details on departure and arrival times, flight routes and aircraft schedules",
    img:"/geo/plane.svg"
  },
  {
    title:"Weather forecast",
    content:"Weather forecast data detailing rain areas in every five minute intervals.",
    img:"/geo/rain.svg"
  },
]

const usecase = [
  {
    title:"Urban Planning",
    content:"Local agencies can use an on-premise map platform to visualize and analyze urban development projects, zoning regulations, and infrastructure plans. It allows for detailed spatial analysis and planning, ensuring data security and compliance with regulatory requirements.",
    img:"/geo/urban_planning.png"
  },
  {
    title:"Site Selection for Retail",
    content:"A retail business can leverage an on-premise map platform for site selection by analyzing factors such as demographic data, traffic patterns, competitor locations, and existing store performance. The platform helps identify high-potential locations that align with the company’s target market, optimize site profitability, and reduce risk by providing a comprehensive spatial analysis before making investment decisions.",
    img:"/geo/site_selection.png"
  },
  {
    title:"Target Marketing Campaign",
    content:"A retail company can use an on-premise map platform to enhance targeted marketing by analyzing customer location data, purchasing behavior, and local trends. By visualizing this data on interactive maps, the company can tailor promotions and advertising efforts to specific geographic areas, optimize campaign reach, and increase engagement by delivering relevant offers to the right audience based on their location and preferences.",
    img:"/geo/target_marketing.png"
  }
]

let whyNeed = [
    {
      title:"Visualize Geospatial Data",
      content:"Transform complex geographic information into intuitive maps for better decision making.",
      img:"/data-feed-platform/Check_File.svg"
    },
    {
      title:"Geographic Analysis",
      content:"Identify trends, patterns and opportunities within different regions which can help in strategic planning.",
      img:"/data-feed-platform/Speed.svg"
    },
    {
      title:"Customer Targeting",
      content:"Enable businesses to understand geographic distribution of their customer which is crucial for tailor marketing strategies.",
      img:"/data-feed-platform/Protect.svg"
    },
    {
      title:"360° Street View Image",
      content:"Providing immersive, interactive experiences that enhance engagement and decision-making.",
      img:"/data-feed-platform/Table_Properties.svg",
    }
]

const GeoPage = () => (
  <Layout>
    <main>
      <title>{PRODUCT_NAME_GEO} | Solutions</title>
      <div className="grid grid-cols-12 gap-5 relative">
          <div className="lg:col-start-3 lg:col-span-8 col-start-2 col-span-10">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-6 flex items-center">
                <div>
                  <h1 className="lg:text-4xl text-2xl leading-normal font-bold">
                    Location and Mapping<br/>Solution
                  </h1>
                  <p className="lg:text-xl text-lg mt-2">
                    Delivers location intelligence right to your fingertips<br/>
                  </p>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 lg:my-10">
                <img className="rounded-lg h-[20rem] lg:h-[28rem] object-fill"  src="/geo/geo_main.png" />
              </div>
            </div>

            <div className="mt-16 col-span-12">
              <h1 className="lg:text-4xl text-xl text-center">Why business need location and <br/>mapping solution</h1>
              <div className="mt-14 grid grid-cols-4 gap-10">
                {
                  whyNeed.map((item)=>(
                    <div className="lg:col-span-1 col-span-4 flex flex-col items-center gap-2 text-center leading-loose">
                      <img className="mb-5 lg:h-20 h-16" src={item.img}/>
                      <p className="text-xl font-bold">{item.title}</p>
                      <p>{item.content}</p>
                    </div>
                  ))
                }
              </div>
            </div>
            
            <div className="col-span-12 mt-32">
              <h1 className="lg:text-4xl text-xl text-center">Explore key features and benefits</h1>
              <div className="grid grid-cols-12 mt-12 gap-7">
                {
                  keyFeature.map((item,idx)=>{
                    if(idx%2!==0){
                      return (
                        <>
                          <div className="lg:col-span-5 col-span-12 h-96 relative scaling-img">
                            <img className="object-cover w-full h-full rounded-2xl" src={item[0].img}/>
                            <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                            <div className="absolute top-0 text-white p-8 lg:w-10/12">
                              <p className="lg:text-xl text-xl font-bold">{item[0].title}</p>
                              <p className="lg:text-md text-md mt-5">{item[0].content}</p>
                            </div>
                          </div>
                          <div className="lg:col-span-7 col-span-12 h-96 relative scaling-img">
                            <img className="object-cover w-full h-full rounded-2xl" src={item[1].img}/>
                            <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                            <div className="absolute top-0 text-white p-8 lg:w-10/12">
                              <p className="lg:text-xl text-xl font-bold">{item[1].title}</p>
                              <p className="lg:text-md text-md mt-5">
                                {item[1].content}
                              </p>
                            </div>
                          </div>
                        </>
                      )
                    }
                    else{
                      return (
                        <>
                        <div className="lg:col-span-7 col-span-12 h-96 relative scaling-img">
                          <img className="object-cover w-full h-full rounded-2xl" src={item[0].img}/>
                          <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                          <div className="absolute top-0 text-white p-8 lg:w-10/12">
                            <p className="lg:text-xl text-xl font-bold">{item[0].title}</p>
                            <p className="lg:text-md text-md mt-5">{item[0].content}</p>
                          </div>
                        </div>
                        <div className="lg:col-span-5 col-span-12 h-96 relative scaling-img">
                          <img className="object-cover w-full h-full rounded-2xl" src={item[1].img}/>
                          <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                          <div className="absolute top-0 text-white p-8 lg:w-10/12">
                            <p className="lg:text-xl text-xl font-bold">{item[1].title}</p>
                            <p className="lg:text-md text-md mt-5">
                              {item[1].content}
                            </p>
                          </div>
                        </div>
                      </>
                      )
                    }
                  })
                }
              </div>
            </div>

            <div className="col-span-12 mt-32">
              <h1 className="lg:text-4xl text-xl text-center">Geospatial Data for Comprehensive Insights</h1>
                <div className="grid grid-cols-3 gap-10 mt-14">
                  {
                    dataset.map((item,index)=>(
                      <div className={`lg:col-span-1 col-span-3 text-center`}>
                        <img className="lg:h-20 h-16 mb-5 mx-auto block" src={item.img}/>
                        <p className="text-xl font-bold">{item.title}</p>
                        <p>{item.content}</p>
                      </div>
                    ))
                  }
                </div>
            </div>

            <div className="mt-32">
              <h1 className="capitalize lg:text-4xl text-xl text-center top-0 sticky py-10 bg-white">
                Explore Use Cases
              </h1>
              {usecase.map((item,idx) => (
                <div className={`grid grid-cols-2 ${idx!==0 && "mt-20"} card_shadow rounded-2xl gap-10 bg-white scaling-img relative`}>
                  <div className="lg:col-span-1 col-span-2 lg:p-10 p-4">
                    <h1 className="lg:text-2xl text-xl capitalize font-bold">{item.title}</h1>
                    <div className="lg:hidden block mt-5 lg:col-span-1 col-span-2">
                      <img className="object-fit w-full h-full " src={item.img} />
                    </div>
                    {
                      item.content.split('.').map((item)=>(
                        <p className="text-md whitespace-normal pt-5">{item}</p>
                      ))
                    }
                  </div>
                  <img className="lg:block hidden object-fit col-span-1 h-full rounded-r-2xl" src={item.img}/>
                </div>
              ))}
            </div>

            <div className="col-span-12 mt-24">
              <div className="call_to_action rounded-2xl mb-14">
                <p className="text-center lg:text-xl text-xl">
                  {"Unlock the Power of Accurate Mapping and Location Insights"}
                </p>
                <div className="flex items-center justify-center mt-5">
                  <a
                    href="/contact"
                    className="uppercase font-firacode bg-white py-3 px-6 text-primary w-max tracking-widest cursor-pointer hover:bg-slate-300"
                  >
                    Let's Talk
                  </a>
                </div>
              </div>
            </div>

            </div>
          </div>
    </main>
  </Layout>
);

export default GeoPage;