import React from "react";
import Footer from "./Footer";
import Navigation from "./Navigation";

const Layout = (props) => {
  return (
    <main  className="flex flex-col h-screen">
      <Navigation />
      {props.children}
      <Footer />
    </main>
  );
};

export default Layout;
