import * as React from "react";
import { PRODUCT_NAME_GEO, PRODUCT_NAME_TRANSMIT } from "../../constant";
import Layout from "../components/Layout";
import "../styles/main.css";

const Contact = () => {
  const submitHandler = (e) => {
    e.preventDefault();
    let fields = [
      {
        objectTypeId: "0-1",
        name: "email",
        value: e.target.email.value,
      },
      {
        objectTypeId: "0-1",
        name: "firstname",
        value: e.target.firstname.value,
      },
      {
        objectTypeId: "0-1",
        name: "lastname",
        value: e.target.lastname.value,
      },
      {
        objectTypeId: "0-1",
        name: "phone",
        value: e.target.phone.value,
      },
      {
        objectTypeId: "0-1",
        name: "email",
        value: e.target.email.value,
      },
      {
        objectTypeId: "0-1",
        name: "country",
        value: e.target.country.value,
      },
      {
        objectTypeId: "0-1",
        name: "message",
        value: e.target.message.value,
      },
    ];
    if (e.target.geo.checked) {
      fields.push({
        objectTypeId: "0-1",
        name: "product_of_interests",
        value: "geo",
      });
    }
    if (e.target.netguard.checked) {
      fields.push({
        objectTypeId: "0-1",
        name: "product_of_interests",
        value: "geo",
      });
    }

    fetch(
      "https://api.hsforms.com/submissions/v3/integration/submit/22080269/70d182f8-e186-4379-a4c8-08bee9fc665a",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          fields: fields,
        }),
      }
    );
    window.alert("Form submitted");
    document.getElementById("contactForm").reset();
  };
  return (
    <div>
      <Layout>
        <title>Contact</title>
        <div className="mt-5 h-full">
          <div className="grid grid-cols-12 gap-0 lg:gap-20 lg:px-20 px-10">
            <div className="hidden lg:block col-span-6 pb-20 h-3/4">
              <img src="/contact.png" className="h-full w-full object-cover" />
            </div>
            <div className="col-span-12 md:col-span-10 lg:col-span-6">
              <h1 className="lg:text-2xl text-xl">Contact Us</h1>
              <p className="lg:text-lg text-sm mt-2">
                Tell us a bit about your company and we’ll show you how we can
                help your business.
              </p>
              <div className="grid grid-cols-12 lg:text-lg text-md mt-10 gap-3">
                <p className="lg:col-span-2 col-span-3 flex justify-between"><span>Address</span><span>:</span></p>
                <p className="lg:col-span-10 col-span-9">
                #06-07, Interlocal Centre, 
                100G Pasir Panjang Rd, 
                Singapore 118523
                </p>
              </div>
              <div className="grid grid-cols-12 lg:text-xl text-md gap-3">
                <p className="lg:col-span-2 col-span-3 flex justify-between"><span>Email</span><span>:</span></p>
                <a className="lg:col-span-10 col-span-9" href="mailto:contact_acias@acias.co"><span className="text-blue-600">contact_acias@acias.co</span></a>
              </div>
              {/* <form id="contactForm" onSubmit={submitHandler} className="pt-5">
                <div className="grid grid-cols-12 gap-4">
                  <div className="col-span-12 md:col-span-6">
                    <div className="pt-5">
                      <div>
                        <label
                          htmlFor="firstname"
                          className="text-xl font-firacode"
                        >
                          First Name:
                        </label>
                      </div>
                      <input
                        id="firstname"
                        name="firstname"
                        className="bg-gray-300 p-2 w-full"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="pt-5">
                      <div>
                        <label
                          htmlFor="lastname"
                          className="text-xl font-firacode"
                        >
                          Last Name:
                        </label>
                      </div>
                      <input
                        id="lastname"
                        name="lastname"
                        className="bg-gray-300 p-2 w-full"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div>
                    <label htmlFor="phone" className="text-xl font-firacode">
                      Phone:
                    </label>
                  </div>
                  <input
                    id="phone"
                    name="phone"
                    className="bg-gray-300 p-2 w-full"
                    required
                  />
                </div>
                <div className="pt-5">
                  <div>
                    <label htmlFor="email" className="text-xl font-firacode">
                      Business Email:
                    </label>
                  </div>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="bg-gray-300 p-2 w-full"
                    required
                  />
                </div>
                <div className="pt-5">
                  <div>
                    <label htmlFor="country" className="text-xl font-firacode">
                      Country:
                    </label>
                  </div>
                  <input
                    id="country"
                    name="country"
                    className="bg-gray-300 p-2 w-full"
                    required
                  />
                </div>
                <div className="pt-5">
                  <div>
                    <label htmlFor="product" className="text-xl font-firacode">
                      Product of interests:
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="geo"
                      id="geo"
                      value="geo"
                    ></input>
                    <label> &nbsp; {PRODUCT_NAME_GEO}</label>
                  </div>
                  <input
                    type="checkbox"
                    name="netguard"
                    id="netguard"
                    value="netguard"
                  ></input>
                  <label> &nbsp; {PRODUCT_NAME_TRANSMIT}</label>
                </div>
                <div className="pt-5">
                  <div>
                    <label htmlFor="message" className="text-xl font-firacode">
                      Additional Notes:
                    </label>
                  </div>
                  <textarea
                    id="message"
                    name="message"
                    className="bg-gray-300  w-full"
                  />
                </div>
                <button
                  type="submit"
                  className="mt-5 col-start-11 uppercase font-firacode bg-primary py-3 px-6 text-white w-max tracking-widest cursor-pointer"
                >
                  Submit
                </button>
              </form> */}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Contact;
