import * as React from "react";
import { COMPANY_NAME } from "../../constant";
import Layout from "../components/Layout";
import "../styles/main.css";

const About = () => (
  <Layout>
    <div className="grid grid-cols-12 gap-8 pt-20 pb-20">
      <div className="text-center lg:text-left lg:col-start-3 col-start-2 lg:col-span-8 col-span-10 pb-20">
        <h1 className="font-bold text-2xl lg:text-4xl">
          EMPOWER ORGANISATIONS WITH ROBUST DATA FEED AND MAPPING SOLUTIONS.
        </h1>
        <p className="text-xl mt-12">
        Our team of experts brings deep knowledge and experience in geospatial analysis and data management, ensuring organizations can navigate complexities, optimize operations, and uncover valuable insights with confidence. <br/><br/>Whether it's through dynamic mapping or real-time data feeds, we empower you to make informed decisions and stay ahead in a rapidly evolving landscape.
        </p>
        <div className="mt-12 h-96">
          <img className="h-full w-full object-cover" src="/about.png" />
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
