import * as React from "react";
import Layout from "../components/Layout";
import "../styles/main.css";

const NotFoundPage = () => {
  return (
    <main>
      <title>Page not found</title>
      <Layout>
        <div className="m-auto text-center">
          <h1 className="text-xl m-2">Oops, page not found!</h1>
          <h2>
            Click&nbsp;
            <a href="/" className="text-primary">here</a> to go home
          </h2>
        </div>
      </Layout>
    </main>
  );
};

export default NotFoundPage;
