import * as React from "react";
import Layout from "../components/Layout";
import "../styles/main.css";
import {
  COMPANY_NAME,
  PRODUCT_NAME_GEO,
  PRODUCT_NAME_TRANSMIT,
} from "../../constant";

const IndexPage = () => {

  let products = [
    {
      name:PRODUCT_NAME_TRANSMIT,
      content:`Handles all stages of data management from start to finish: Ingestion, processing, and delivery.
      Keep your data safe from unauthorized access and corruption.
      Ensure accurate data delivery with no loss or duplication`,
      link_desc:`Explore more from ${PRODUCT_NAME_TRANSMIT}`,
      link:"/solutions/secure-data-feed",
      img:`/home/product1_img.png`
    },
    {
      name:PRODUCT_NAME_GEO,
      content:`On premises mapping platform that provides full control and security over your geospatial data.
      Customizable Mapping: Adjust the platform to fit your specific needs.
      Up-to-date location informations with local details.`,
      link_desc:`Explore more from ${PRODUCT_NAME_GEO}`,
      link:"/solutions/location-and-mapping",
      img:`/home/product2_img.png`

    }
  ]

  let callToAction = "We are here to help you optimize your geographic and data management needs with our cutting-edge solutions."

  return (
    <Layout>
      <title>{COMPANY_NAME} | Home</title>
      <div className="grid grid-cols-12 gap-5 relative">
        <div className="lg:col-start-3 lg:col-span-8 col-start-2 col-span-10">
          <div className="grid grid-cols-2 gap-8 lg:my-20">
            <div className="lg:col-span-1 col-span-2 flex items-center">
              <div>
                <h1 className="lg:text-4xl leading-normal font-bold lg:block hidden">Peace of mind <br/> Data Management and <br/> Mapping Solutions</h1>
                <h1 className="lg:hidden leading-normal font-bold text-2xl">Peace of mind Data Management and Mapping Solutions</h1>
                <p className="lg:text-xl mt-4 lg:block hidden">
                  Led by veteran data experts with experience in
                </p>
                <p className="lg:text-xl mb-10 lg:block  hidden">
                  secure data transfer
                  and mapping
                </p>
                <p className="lg:hidden mt-2 lg:mb-10 mb-4 text-lg mt-4">
                  Led by veteran data experts with experience in
                  secure data transfer
                  and mapping
                </p>
              </div>
            </div>
            <div className="lg:col-span-1 col-span-2 ">
              <img className="rounded-lg h-full lg:h-[28rem] object-fill"  src="/home/home_main.png" />
            </div>
          </div>

          <div className="lg:my-24 mt-24 mb-4">
            <h1 className="block lg:text-4xl text-2xl leading-normal font-bold text-center">
              Our Solutions
            </h1>
          </div>
          
            <div className="lg:my-24 my-12">
              <div className="grid grid-cols-12 gap-5s">
                <div className="col-span-12 lg:col-start-2 lg:col-span-5  items-center flex items-center order-2 lg:order-1">
                  <div>
                    <h1 className="block lg:text-4xl text-xl leading-normal font-bold leading-none">
                      {products[0].name} 
                    </h1>
                    <div>
                      {products[0].content.split('.').map((content)=>(
                        <p className="lg:text-lg text-lg mt-5">{content}</p>
                      ))}
                    </div>
                    <a className="mt-5 block leading-normal font-bold cursor-pointer scaling-img link" href={products[0].link} >{products[0].link_desc}</a>
                  </div>
                </div>
                <div className="col-span-12 lg:col-span-6 items-center order-1 lg:order-2">
                  <img className="w-full scaling-img mb-4" src={products[0].img}/>
                </div>
              </div>
            </div>

            <div className="my-24">
              <div className="grid grid-cols-12 gap-5">
                <div className="col-span-12 lg:col-start-0 lg:col-span-6 items-center order-2 lg:order-1">
                    <img className="w-full scaling-img mb-4" src={products[1].img}/>
                </div>
                <div className="col-span-12 lg:col-start-8 lg:col-span-6 items-center flex items-center order-2 lg:order-1">
                  <div>
                    <h1 className="block lg:text-4xl text-xl leading-normal font-bold leading-none">
                      {products[1].name} 
                    </h1>
                    <div>
                      {products[1].content.split('.').map((content)=>(
                        <p className="lg:text-lg text-lg mt-5">{content}</p>
                      ))}
                    </div>
                    <a className="mt-5 block leading-normal font-bold cursor-pointer scaling-img link" href={products[0].link}>{products[1].link_desc}</a>
                  </div>
                </div>
              </div>
            </div>

        <div className="call_to_action rounded-2xl mb-14">
          <p className="text-center lg:text-xl text-xl">
            {callToAction}
          </p>
          <div className="flex items-center justify-center mt-5">
            <a
              href="/contact"
              className="uppercase font-firacode bg-white py-3 px-6 text-primary w-max tracking-widest cursor-pointer hover:bg-slate-300"
            >
              Let's Talk
            </a>
          </div>
        </div>

        </div>
      <div className="absolute lg:block hidden" style={{bottom:"10%",zIndex:-1}}>
        <img src="/home/dot_pattern.png" />
      </div>
      <div className="absolute lg:block hidden" style={{right:0,bottom:"10%",zIndex:-1}}>
        <img src="/home/curve_background.png" />
      </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
