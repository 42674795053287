import React from "react";
import {
  COMPANY_NAME,
  PRODUCT_NAME_GEO,
  PRODUCT_NAME_TRANSMIT,
} from "../../constant";

const Footer = () => {
  function scrollToTop() {
          window.scrollTo({
              top: 0,
          });
      }

  return (
    <div className="bg-primary text-white py-20 w-full grid grid-cols-12 md:gap-5 relative md:mt-auto">
      <a className="col-start-2 md:col-start-2 cursor-pointer text-xl mb-5" href="/">
        {COMPANY_NAME}
      </a>
      <div className="row-start-2 md:row-start-1 flex flex-col gap-5 col-start-2 md:col-start-7 sm:w-max">
        <div className="font-firacode text-sm md:text-lg tracking-widest">SOLUTIONS</div>
        <a onClick={scrollToTop} href="/solutions/secure-data-feed" className="cursor-pointer text-xs md:text-base">
          {PRODUCT_NAME_TRANSMIT}
        </a>
        <a onClick={scrollToTop} className="cursor-pointer text-xs md:text-base" href="/solutions/location-and-mapping">
          {PRODUCT_NAME_GEO}
        </a>
      </div>
      <div className="row-start-2 md:row-start-1 flex flex-col gap-5 col-start-10 md:col-start-11 sm:w-max">
        <p className="uppercase font-firacode text-sm md:text-lg tracking-widest">
          COMPANY
        </p>
        <a onClick={scrollToTop} href="/about" className="cursor-pointer text-xs md:text-base">
          About Us
        </a>
        <a onClick={scrollToTop} href="/contact" className="cursor-pointer text-xs md:text-base">
          Contact Us
        </a>
        <a onClick={scrollToTop} href="/privacy-policy" className="cursor-pointer text-xs md:text-base">
          Privacy Policy
        </a>
      </div>
      <p className="text-xs bottom-0 absolute left-0 right-0 m-auto text-center uppercase font-firacode my-4">
        © 2024 {COMPANY_NAME} TECHNOLOGIES PTE LTD. ALL RIGHTS RESERVED.
      </p>
    </div>
  );
};

export default Footer;
