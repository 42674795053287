import React from "react";
import Layout from "../../components/Layout";
import { PRODUCT_NAME_TRANSMIT } from "../../../constant";
import ContactUs from "../../components/ContactUs";
import "../../styles/main.css";

const howItWorksContent = [
  {
    title: "Raw Data Ingestion",
    description:
      `Raw data from various sources is ingested through a high-throughput pipeline designed for speed and large volumes. 
      
      It supports both real-time and batch processing, ensuring effective and scalable data handling. 
      
      By accommodating diverse formats and maintaining robust performance, the pipeline enables timely insights and reliable data management.`,
    src: "/data-feed-platform/stage1.gif",
  },
  {
    title: "Pre-processing Screening",
    description:
      `Unsafe raw data from multiple sources undergoes a security screening to identify and eliminate harmful or irrelevant traffic. 
      
      The process analyzes the data for threats, anomalies, or suspicious patterns. 
      
      By filtering out suspicious traffic, the system ensures only clean, validated data continues, protecting data integrity and network security.`,
    src: "/data-feed-platform/stage2.gif",
  },
  {
    title: "Clean, Enrich, Transform",
    description:
      `Validated data is transformed into a format suitable for analysis and decision-making. 
      
      This includes reshaping structures, aggregating information, and applying rules to enhance and standardize the data.
      
      By following predefined configurations, the data is optimized for accuracy, consistency, and compatibility with analytical tools.`,
    src: "/data-feed-platform/stage3.gif",
  },
  {
    title: "Transmit Through Data Diode",
    description:
      `Consumable data is transmitted via high-performance data diodes over the Internet, ensuring secure and efficient transfer. 
      
      These diodes create a one-way pathway, preventing security threats from entering the network. 
      
      By using multiple data diodes, organizations achieve reliable, high-speed transmission while maintaining control over data flow and protecting against cyber threats.`,
    src: "/data-feed-platform/stage4.gif",
  },
  // {
  //   title: "Data Feed Arrival Screening",
  //   description:
  //     "Upon arrival, the data feed undergoes another round of security screening to ensure no unintended pollutants hides within the data feeds.",
  //   src: "/data-feed-platform/stage5.gif",
  // },
];

let whyNeed = [
  {
    title:"Integrity Assurance",
    content:"Guarantees that data remains accurate and unaltered during transmission",
    img:"/data-feed-platform/Check_File.svg",
  },
  {
    title:"High Speed Process and Transmit",
    content:"Ensure rapid, efficient delivery of data",
    img:"/data-feed-platform/Speed.svg",
  },
  {
    title:"Protect Data infrastructure",
    content:"Safeguards proprietary and confidential businesses data and informations",
    img:"/data-feed-platform/Protect.svg",
  },
  {
    title:"Ensure operational continuity",
    content:"Reliable and timely data delivery according to predefined schedules maintaining operational efficiency",
    img:"/data-feed-platform/Table_Properties.svg",
  },
]

let callToAction = "Protect your data feed supply chain with robust security for seamless, secure transmission."

const NetGuardPage = () => {
  return (
    <Layout>
      <title>{PRODUCT_NAME_TRANSMIT} | Solutions</title>
      <div className="relative">
        <div className="grid grid-cols-12 gap-5 relative">
          <div className="lg:col-start-3 lg:col-span-8 col-start-2 col-span-10">
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-6 flex items-center">
                <div>
                  <h1 className="lg:text-4xl text-2xl leading-normal font-bold">
                    Secure Data Feed<br/>Solution
                  </h1>
                  <p className="lg:text-xl text-lg lg:mt-2 mt-2">
                    End-to-end Data Feed Platform for Secure,<br className="lg:block hidden"/> Reliable, High Speed transmission
                  </p>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 lg:my-14">
                <img className="rounded-lg h-[20rem] lg:h-[28rem] object-fit"  src="/data-feed-platform/data_feed_main.png" />
              </div>
            </div>

            <div className="col-span-12 mt-16">
              <h1 className="lg:text-4xl text-xl text-center">Why business need secure <br/>data feed solution</h1>
              <div className="mt-14 grid grid-cols-4 gap-10">
                {
                  whyNeed.map((item)=>(
                    <div className="lg:col-span-1 col-span-4 flex flex-col items-center gap-2 text-center leading-loose">
                      <img className="mb-5 lg:h-20 h-16" src={item.img}/>
                      <p className="text-xl font-bold">{item.title}</p>
                      <p>{item.content}</p>
                    </div>
                  ))
                }
              </div>
            </div>

            <div className="col-span-12 mt-32">
              <h1 className="lg:text-4xl text-xl text-center">Explore key features and benefits</h1>

              <div className="grid grid-cols-12 mt-12 gap-7">
                <div className="lg:col-span-7 col-span-12 h-96 relative scaling-img">
                  <img className="object-cover w-full h-full rounded-2xl" src="/data-feed-platform/data_feed_1.jpg"/>
                  <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                  <div className="absolute top-0 text-white p-8 lg:w-10/12">
                    <p className="lg:text-xl text-xl font-bold">Unidirectional Data Transmission Pipeline</p>
                    <p className="lg:text-md text-md mt-5">Utilize data diodes for data transmission enhances security by preventing reverse data flow.<br/><br/> 
                    Ensuring data integrity and reduce risk from potential threats.</p>
                  </div>
                </div>
                <div className="lg:col-span-5 col-span-12 h-96 relative scaling-img">
                  <img className="object-cover w-full h-full rounded-2xl" src="/data-feed-platform/data_feed_3.jpg"/>
                  <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                  <div className="absolute top-0 text-white p-8 lg:w-10/12">
                    <p className="lg:text-xl text-xl font-bold">Seemless Data Ingestion</p>
                    <p className="lg:text-md text-md mt-5">
                      Set up data ingestion pipelines that effortlessly integrate various data sources. <br/><br/> 
                      Compatible to diverse data formats: files, databases.
                    </p>
                  </div>

                </div>
                <div className="lg:col-span-5 col-span-12 h-96 relative scaling-img">
                  <img className="object-cover w-full h-full rounded-2xl" src="/data-feed-platform/data_feed_2.jpg"/>
                  <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                  <div className="absolute top-0 text-white p-8 lg:w-10/12">
                    <p className="lg:text-xl text-xl font-bold">Advanced Threat Detection</p>
                    <p className="lg:text-md text-md mt-5">Comes with advanced threat detection to identify and eliminate potential security threats within data sources.<br/> <br/> Ensuring that the data remains clean and secure.</p>
                  </div>
                </div>
                <div className="lg:col-span-7 col-span-12 h-96 relative scaling-img">
                  <img className="object-cover w-full h-full rounded-xl" src="/data-feed-platform/data_feed_4.jpg"/>
                  <div className="container_shadow absolute top-0 text-white p-8 w-full h-full rounded-2xl bg-gradient-to-r from-slate-700 to-transparent opacity-90"/>
                  <div className="absolute top-0 text-white p-8 lg:w-10/12 h-full">
                    <p className="lg:text-xl text-xl font-bold">Monitoring Platform</p>
                    <p className="lg:text-md text-md mt-5">Web platform that provides user the ability to oversee transmission status and trigger alerts for failures transmission.<br/><br/> 
                    Enabling swift resolution of issues and ensuring continuos and dependable data transmission. </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-12 mt-32">
              <h1 className="capitalize lg:text-4xl text-xl text-center top-0 sticky py-10 bg-white">
                How It Works?
              </h1>
              {howItWorksContent.map((content,i) => (
                <div className={`grid grid-cols-2 ${i!==0 && "mt-20"} card_shadow rounded-2xl gap-10 bg-white`}>
                  <div className="lg:col-span-1 col-span-2 lg:p-10 p-4">
                    <h1 className="lg:text-2xl text-xl capitalize font-bold">{content.title}</h1>
                    <div className="lg:hidden block mt-5 lg:col-span-1 col-span-2">
                      <img className="object-fit w-full h-full bg-[#f9f8f9] p-10" src={content.src} />
                    </div>
                    {
                      content.description.split('.').map((item)=>(
                        <p className="lg:text-md text-md whitespace-normal pt-5">{item}</p>
                      ))
                    }
                  </div>
                  <div className="lg:block hidden lg:col-span-1 col-span-2">
                    <img className="object-fit w-full h-full rounded-r-2xl  bg-[#f9f8f9] p-10" src={content.src} />
                  </div>
                </div>
              ))}
            </div>
            
            <div className="col-span-12 mt-32">
              <div className="call_to_action rounded-2xl mb-14">
                <p className="text-center lg:text-xl text-xl">
                  {callToAction}
                </p>
                <div className="flex items-center justify-center mt-5">
                  <a
                    href="/contact"
                    className="uppercase font-firacode bg-white py-3 px-6 text-primary w-max tracking-widest cursor-pointer hover:bg-slate-300"
                  >
                    Let's Talk
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div className="absolute" style={{top:"30%",zIndex:-1}}>
          <img src="/home/dot_pattern.png" />
        </div>
        <div className="absolute" style={{bottom:"10%",zIndex:-1}}>
          <img src="/home/dot_pattern.png" />
        </div>
        <div className="absolute" style={{right:0,bottom:"10%",zIndex:-1}}>
          <img src="/home/curve_background.png" />
        </div>
      </div>
    </Layout>
  );
};

export default NetGuardPage;
